.terminal {
  border-radius: 5px 5px 0 0;
  position: relative;
  .top {
    background: #e8e6e8;
    color: black;
    padding: 5px;
    border-radius: 5px 5px 0 0;
  }
  .btns {
    position: absolute;
    top: 7px;
    left: 5px;
  }
  .circle {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 15px;
    margin-left: 2px;
    border: 1px solid;
  }
  pre.body {
    margin: 0;
    padding: 25px 0 0 10px;
  }
}

.title {
  text-align: center;
}

.red {
  background: #ec6a5f;
  border-color: #d04e42;
}

.green {
  background: #64cc57;
  border-color: #4ea73b;
}

.yellow {
  background: #f5c04f;
  border-color: #d6a13d;
}

.clear {
  clear: both;
}

.terminal .body {
  padding: 0px;
  overflow: auto;
}

.space {
  margin: 0;
}

.shadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
