@charset "utf-8";

$base-font-family: "Ubuntu", "Open Sans", "Helvetica Neue", "DejaVu Sans", Arial, "PingFang SC", "Noto Sans CJK SC", "Hiragino Sans GB", "微软雅黑", Helvetica, sans-serif;
$base-font-size: 14px;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;

$code-font-family: 'Fira Code', Consolas, Menlo, Monaco, "DejaVu Sans Mono", "Liberation Mono",  "Courier New", Courier, SimSun, monospace;
$code-font-size: 13px;

// Width of the content area
$content-width: 1200px;

$on-palm: 600px;
$on-laptop: 800px;

$color-link: #036;
$color-link-hover: #069;

$header-bg: #607D8B;
$header-bg-hover: #78909C;
$header-link: #CFD8DC;
$header-fg: #FFF;

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import "base",
"layout",
"syntax-highlighting",
"easybook",
"term",
"youtube";
