.wrapper {
  margin: 0 auto;
  max-width: 1100px;
}

@media (max-width: 1120px) {
  .wrapper {
    max-width: 800px;
  }
}

.header {
  line-height: 45px;
  background-color: $header-bg;
  padding: 1px;
  margin: 0;

  &:after {
    clear: both;
    content: ' ';
    display: block;
  }

  .site-title {
    float: left;
    color: $header-fg;
    text-decoration: none;
    font-size: $base-font-size * 2.1;
    line-height: $base-font-size * 2;
    img {
      height: 42px;
      width: auto;
      border-radius: 50%;
    }
  }
  .site-nav {
    text-align: right;

    a {
      text-decoration: none;
      color: $header-link;
      padding: 10px 20px;
      white-space: nowrap;

      &:hover, &:focus {
        background: $header-bg-hover;
      }
    }
  }
}

.page-content {
  background: #e0e0e3;
  padding: 30px 0;

  .wrapper {
    position: relative;
    background: #FAFAFA;
    box-shadow: 0 1px 4px #999;
    outline: 1px solid #CCC;
    &:after {
      clear: both;
      content: '';
      display: block;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 800px;
      width: 300px;
      height: 100%;
      top: 0;
      background: #EEE;
    }
  }

  img {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
}

// Colum
.col-main {
  position: relative;
  box-sizing: border-box;
  padding: 30px;
  width: 800px;
  float: left;
}

.col-second {
  position: relative;
  box-sizing: border-box;
  float: left;
  width: 300px;
  padding: 20px;
  background: #EEE;
  z-index: 10;
}

.col-box {
  color: #666;
  font-size: $small-font-size;
  padding-bottom: 20px;

  p {
    margin: 0;
  }
  ul {
    padding-left: 20px;
    margin: 0;
  }

  &:not(:first-child) {
    border-top: 1px solid #ccc;
    padding-top: 20px;
  }

  .col-box-title {
    color: #333;
    margin-bottom: 10px;
    font-size: $small-font-size * 1.1;
  }
}

@media (max-width: 1120px) {
  .page-content .wrapper:before {
    display: none;
  }
  .col-main,
  .col-second {
    width: auto;
    float: none;
  }
  .col-box {
    width: 50%;
    float: left;
    margin-bottom: 10px;
  }
  .col-box:not(:first-child) {
    border-top: none;
    padding-top: 0;
  }
  .col-second:after {
    clear: both;
    display: block;
    content: ' ';
  }
}

.col-box-author {
  text-align: center;
  .avatar {
    width: 135px;
    height: 135px;
    border-radius: 100%;
    display: block;
  }
  .name {
    font-size: $base-font-size;
  }
  .contact {
    margin-top: 10px;
    a {
      background: #999;
      color: #eee;
      padding: 5px 10px;
      text-decoration: none;
      border-radius: 5px;
      white-space: nowrap;

      &:hover {
        transition: background 0.3s ease;
        background: #666;
      }
    }
  }
}

// footer
.footer {
  padding: 60px 0;
  background-color: #e0e0e3;
  text-align: center;
  color: #666;
  font-size: $small-font-size;
}

@import "post", "home";

@include media-query($on-laptop) {
  .page-content {
    padding: 0;
  }
  .footer {
    padding: 15px 0;
  }
}

@include media-query($on-palm) {
  .header {
    &, .site-nav {
      text-align: center;
    }
    .site-title {
      float: none;
    }
  }
  .col-main {
    padding: 10px;
  }
  .col-box {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
  pre {
    padding: 10px;
  }
}
