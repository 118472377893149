body {
  margin: 0;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  background: #e0e0e3;
}

a {
  text-decoration: none;
  color: #036;
}

a:hover, a:focus {
  text-decoration: none;
  color: #069;
}

blockquote {
  background: #FFF;
  margin: 0;
  padding: 5px 20px;
  border-left: 10px solid #CCC;
}

code, pre {
  font-family: $code-font-family;
  font-size: $code-font-size;
}

code {
  color: #900;
  background-color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  word-break: break-all;
}

pre {
  overflow-x: auto;
  padding: 15px;
}

pre, pre code {
  color: #fafafa;
  background-color: #223;
}

pre code {
  padding: 0;
}

.hide {
  display: none;
}

.center {
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 10pt auto;
  background: #FFF;

  td, th {
    border: 1px solid #CCC;
    padding: 5px 15px;
    margin: 0;
  }

  th {
    border-color: #555;
    background: #555;
    color: #eee;
    font-weight: normal;
  }

  tr:hover {
    background: rgba(255, 255, 0, 0.2);
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

.error {
  color: red;
}

.floated {
  float: left;
  width: 150px;
  padding-right: 10px;
}

.footnotes::before {
  content: "Footnotes";
  font-size: 16px;
}

dl {
  margin: 0;
  padding: 0px 10px;
  background: #fff;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
}

dt {
  font-weight: 600;
  margin: .5em 0 0 0;
  padding: 0;
}

dd {
  display: list-item;
  list-style: square;
  margin: 0 0 0 2em;
  padding: .5em;
}

abbr[title] {
  cursor: context-menu;
}

.pag {
  text-align: right;
}
